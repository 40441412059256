import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Password } from 'primereact/password';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useAddPage from 'hooks/useAddPage';
const LeadsagenzieAddPage = (props) => {
		const app = useApp();
	
	//form validation rules
	const validationSchema = yup.object().shape({
		cognome: yup.string().required().label("Cognome"),
		nome: yup.string().required().label("Nome"),
		azienda: yup.string().nullable().label("Azienda"),
		email: yup.string().email().nullable().label("Email"),
		telefono: yup.string().required().label("Telefono"),
		data_contatto: yup.string().nullable().label("Data Contatto"),
		nota: yup.string().nullable().label("Nota"),
		data_richiamo: yup.string().nullable().label("Data Richiamo"),
		attivo: yup.string().required().label("Attivo"),
		password: yup.string().nullable().label("Password"),
		confirm_password: yup.string().label("Confirm Password").oneOf([yup.ref('password')], "Your passwords do not match")
	});
	
	//form default values
	const formDefaultValues = {
		cognome: '', 
		nome: '', 
		azienda: '', 
		email: '', 
		telefono: '', 
		data_contatto: new Date(), 
		nota: '', 
		data_richiamo: new Date(), 
		attivo: '', 
		password: "NULL", 
		confirm_password: '', 
	}
	
	//page hook where logics resides
	const pageController =  useAddPage({ props, formDefaultValues, afterSubmit });
	
	// destructure and grab what the page needs
	const { formData, resetForm, handleSubmit, submitForm, pageReady, loading, saving, inputClassName } = pageController;
	
	//event raised after form submit
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		resetForm();
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/leadsagenzie`);
		}
	}
	
	// page loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	
	//page has loaded any required data and ready to render
	if(pageReady){
		return (
<main id="LeadsagenzieAddPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Aggiungere nuova"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={(values, actions) =>submitForm(values)}>
                            {(formik) => 
                            <>
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Cognome *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="cognome"  onChange={formik.handleChange}  value={formik.values.cognome}   label="Cognome" type="text" placeholder="accedere Cognome"        className={inputClassName(formik?.errors?.cognome)} />
                                                <ErrorMessage name="cognome" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Nome *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="nome"  onChange={formik.handleChange}  value={formik.values.nome}   label="Nome" type="text" placeholder="accedere Nome"        className={inputClassName(formik?.errors?.nome)} />
                                                <ErrorMessage name="nome" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Azienda 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="azienda"  onChange={formik.handleChange}  value={formik.values.azienda}   label="Azienda" type="text" placeholder="accedere Azienda"        className={inputClassName(formik?.errors?.azienda)} />
                                                <ErrorMessage name="azienda" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Email 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="email"  onChange={formik.handleChange}  value={formik.values.email}   label="Email" type="email" placeholder="accedere Email"        className={inputClassName(formik?.errors?.email)} />
                                                <ErrorMessage name="email" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Telefono *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="telefono"  onChange={formik.handleChange}  value={formik.values.telefono}   label="Telefono" type="text" placeholder="accedere Telefono"        className={inputClassName(formik?.errors?.telefono)} />
                                                <ErrorMessage name="telefono" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Data Contatto 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Calendar name="data_contatto" showButtonBar className={inputClassName(formik?.errors?.data_contatto)} dateFormat="yy-mm-dd" value={formik.values.data_contatto} onChange={formik.handleChange} showIcon        />
                                                <ErrorMessage name="data_contatto" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Nota 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputTextarea name="nota"  className={inputClassName(formik?.errors?.nota)}   value={formik.values.nota} placeholder="accedere Nota" onChange={formik.handleChange}   >
                                                </InputTextarea>
                                                <ErrorMessage name="nota" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Data Richiamo 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Calendar name="data_richiamo" showButtonBar className={inputClassName(formik?.errors?.data_richiamo)} dateFormat="yy-mm-dd" value={formik.values.data_richiamo} onChange={formik.handleChange} showIcon        />
                                                <ErrorMessage name="data_richiamo" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Attivo *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Dropdown  name="attivo"     optionLabel="label" optionValue="value" value={formik.values.attivo} onChange={formik.handleChange} options={app.menus.pec} label="Attivo"  placeholder="Seleziona un valore ..."  className={inputClassName(formik?.errors?.attivo)}   />
                                                <ErrorMessage name="attivo" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Password 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Password name="password" value={formik.values.password} onChange={formik.handleChange} label="Password" placeholder="accedere Password"  inputClassName="w-full" toggleMask feedback className={inputClassName(formik?.errors?.password)} />
                                                <ErrorMessage name="password" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Confirm Password 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Password name="confirm_password" id="confirm_password" className={inputClassName(formik?.errors?.comfirm_password)} inputClassName="w-full" feedback={false} toggleMask  value={formik.values.confirm_password} onChange={formik.handleChange} label="conferma password" placeholder="conferma password"  />
                                                <ErrorMessage name="confirm_password" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)} className="p-button-primary" type="submit" label="Sottoscrivi" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            </>
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}

//page props and default values
LeadsagenzieAddPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'leadsagenzie',
	apiPath: 'leadsagenzie/add',
	routeName: 'leadsagenzieadd',
	submitButtonLabel: "Sottoscrivi",
	formValidationError: "Il modulo non è valido",
	formValidationMsg: "Si prega di compilare il modulo",
	msgTitle: "Crea registro",
	msgAfterSave: "Record aggiunto",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default LeadsagenzieAddPage;
