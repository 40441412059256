import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import { Uploader } from 'components/Uploader';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
const ImmobiliEditPage = (props) => {
		const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
		tipologia: yup.string().nullable().label("Tipologia"),
		titolo: yup.string().required().label("Titolo"),
		descrizione: yup.string().nullable().label("Descrizione"),
		prezzo: yup.number().nullable().label("Prezzo"),
		lotto: yup.string().nullable().label("Lotto"),
		tribunale: yup.string().nullable().label("Tribunale"),
		numero_esecuzione: yup.string().nullable().label("Numero Esecuzione"),
		data_asta: yup.string().required().label("Data Asta"),
		ora_asta: yup.string().nullable().label("Ora Asta"),
		foto1: yup.string().nullable().label("Foto1"),
		stima: yup.number().nullable().label("Stima"),
		data_stima: yup.string().nullable().label("Data Stima"),
		perizia: yup.string().nullable().label("Perizia"),
		ordinanza: yup.string().nullable().label("Ordinanza"),
		avviso: yup.string().nullable().label("Avviso"),
		indirizzo_immobile: yup.string().nullable().label("Indirizzo Immobile"),
		comune_immobile: yup.string().nullable().label("Comune Immobile"),
		termine_presentazione: yup.string().nullable().label("Termine Presentazione"),
		cauzione: yup.string().nullable().label("Cauzione"),
		provincia_immobile: yup.string().nullable().label("Provincia Immobile"),
		asta_diretta: yup.string().nullable().label("Asta Diretta"),
		valore_aggiudicazione: yup.number().nullable().label("Valore Aggiudicazione")
	});
	// form default values
	const formDefaultValues = {
		tipologia: '', 
		titolo: '', 
		descrizione: '', 
		prezzo: '', 
		lotto: '', 
		tribunale: '', 
		numero_esecuzione: '', 
		data_asta: new Date(), 
		ora_asta: new Date(), 
		foto1: '', 
		stima: "NULL", 
		data_stima: new Date(), 
		perizia: '', 
		ordinanza: '', 
		avviso: '', 
		indirizzo_immobile: '', 
		comune_immobile: '', 
		termine_presentazione: new Date(), 
		cauzione: '', 
		provincia_immobile: "NULL", 
		asta_diretta: '', 
		valore_aggiudicazione: '', 
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/immobili`);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="ImmobiliEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="modificare"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik
                            initialValues={formData}
                            validationSchema={validationSchema} 
                            onSubmit={(values, actions) => {
                            submitForm(values);
                            }
                            }
                            >
                            { (formik) => {
                            return (
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Tipologia 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Dropdown  name="tipologia"     optionLabel="label" optionValue="value" value={formik.values.tipologia} onChange={formik.handleChange} options={app.menus.tipologia} label="Tipologia"  placeholder="Seleziona un valore ..."  className={inputClassName(formik?.errors?.tipologia)}   />
                                                <ErrorMessage name="tipologia" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Titolo *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="titolo"  onChange={formik.handleChange}  value={formik.values.titolo}   label="Titolo" type="text" placeholder="Titolo"        className={inputClassName(formik?.errors?.titolo)} />
                                                <ErrorMessage name="titolo" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Descrizione 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputTextarea name="descrizione"  className={inputClassName(formik?.errors?.descrizione)}   value={formik.values.descrizione} placeholder="accedere Descrizione" onChange={formik.handleChange}   >
                                                </InputTextarea>
                                                <ErrorMessage name="descrizione" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Prezzo 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="prezzo"  onChange={formik.handleChange}  value={formik.values.prezzo}   label="Prezzo" type="number" placeholder="Prezzo (es. 120000.00)"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.prezzo)} />
                                                <ErrorMessage name="prezzo" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Lotto 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="lotto"  onChange={formik.handleChange}  value={formik.values.lotto}   label="Lotto" type="text" placeholder="accedere Lotto"        className={inputClassName(formik?.errors?.lotto)} />
                                                <ErrorMessage name="lotto" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Tribunale 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="tribunale"  onChange={formik.handleChange}  value={formik.values.tribunale}   label="Tribunale" type="text" placeholder="accedere Tribunale"        className={inputClassName(formik?.errors?.tribunale)} />
                                                <ErrorMessage name="tribunale" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Numero Esecuzione 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="numero_esecuzione"  onChange={formik.handleChange}  value={formik.values.numero_esecuzione}   label="Numero Esecuzione" type="text" placeholder="accedere Numero Esecuzione"        className={inputClassName(formik?.errors?.numero_esecuzione)} />
                                                <ErrorMessage name="numero_esecuzione" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Data Asta *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Calendar name="data_asta" showButtonBar className={inputClassName(formik?.errors?.data_asta)} dateFormat="yy-mm-dd" value={formik.values.data_asta} onChange={formik.handleChange} showIcon        />
                                                <ErrorMessage name="data_asta" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Ora Asta 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Calendar name="ora_asta" value={formik.values.ora_asta} onChange={formik.handleChange} showButtonBar className={inputClassName(formik?.errors?.ora_asta)}     showTime timeOnly     />
                                                <ErrorMessage name="ora_asta" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Foto1 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <div className={inputClassName(formik?.errors?.foto1)}>
                                                    <Uploader name="foto1" showUploadedFiles value={formik.values.foto1} uploadPath="fileuploader/upload/foto1" onChange={(paths) => formik.setFieldValue('foto1', paths)} fileLimit={1} maxFileSize={3} accept=".jpg,.png,.gif,.jpeg" multiple={false} label="Scegli i file o trascina i file qui" onUploadError={(errMsg) => app.flashMsg('Upload error', errMsg, 'error')} />
                                                </div>
                                                <ErrorMessage name="foto1" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Stima 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="stima"  onChange={formik.handleChange}  value={formik.values.stima}   label="Stima" type="number" placeholder="Stima"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.stima)} />
                                                <ErrorMessage name="stima" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Data Stima 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Calendar name="data_stima" showButtonBar className={inputClassName(formik?.errors?.data_stima)} dateFormat="yy-mm-dd" value={formik.values.data_stima} onChange={formik.handleChange} showIcon        />
                                                <ErrorMessage name="data_stima" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Perizia 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <div className={inputClassName(formik?.errors?.perizia)}>
                                                    <Uploader name="perizia" showUploadedFiles value={formik.values.perizia} uploadPath="fileuploader/upload/perizia" onChange={(paths) => formik.setFieldValue('perizia', paths)} fileLimit={1} maxFileSize={3} accept=".jpg,.png,.gif,.jpeg" multiple={false} label="Scegli i file o trascina i file qui" onUploadError={(errMsg) => app.flashMsg('Upload error', errMsg, 'error')} />
                                                </div>
                                                <ErrorMessage name="perizia" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Ordinanza 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <div className={inputClassName(formik?.errors?.ordinanza)}>
                                                    <Uploader name="ordinanza" showUploadedFiles value={formik.values.ordinanza} uploadPath="fileuploader/upload/ordinanza" onChange={(paths) => formik.setFieldValue('ordinanza', paths)} fileLimit={1} maxFileSize={3} accept=".jpg,.png,.gif,.jpeg" multiple={false} label="Scegli i file o trascina i file qui" onUploadError={(errMsg) => app.flashMsg('Upload error', errMsg, 'error')} />
                                                </div>
                                                <ErrorMessage name="ordinanza" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Avviso 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <div className={inputClassName(formik?.errors?.avviso)}>
                                                    <Uploader name="avviso" showUploadedFiles value={formik.values.avviso} uploadPath="fileuploader/upload/avviso" onChange={(paths) => formik.setFieldValue('avviso', paths)} fileLimit={1} maxFileSize={3} accept=".jpg,.png,.gif,.jpeg" multiple={false} label="Scegli i file o trascina i file qui" onUploadError={(errMsg) => app.flashMsg('Upload error', errMsg, 'error')} />
                                                </div>
                                                <ErrorMessage name="avviso" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Indirizzo Immobile 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="indirizzo_immobile"  onChange={formik.handleChange}  value={formik.values.indirizzo_immobile}   label="Indirizzo Immobile" type="text" placeholder="Indirizzo Immobile"        className={inputClassName(formik?.errors?.indirizzo_immobile)} />
                                                <ErrorMessage name="indirizzo_immobile" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Comune Immobile 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="comune_immobile"  onChange={formik.handleChange}  value={formik.values.comune_immobile}   label="Comune Immobile" type="text" placeholder="Comune Immobile"        className={inputClassName(formik?.errors?.comune_immobile)} />
                                                <ErrorMessage name="comune_immobile" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Termine Presentazione 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Calendar name="termine_presentazione" showButtonBar className={inputClassName(formik?.errors?.termine_presentazione)} dateFormat="yy-mm-dd" value={formik.values.termine_presentazione} onChange={formik.handleChange} showIcon        />
                                                <ErrorMessage name="termine_presentazione" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Cauzione 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="cauzione"  onChange={formik.handleChange}  value={formik.values.cauzione}   label="Cauzione" type="text" placeholder="Cauzione"        className={inputClassName(formik?.errors?.cauzione)} />
                                                <ErrorMessage name="cauzione" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Provincia Immobile 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="provincia_immobile"  onChange={formik.handleChange}  value={formik.values.provincia_immobile}   label="Provincia Immobile" type="text" placeholder="accedere Provincia Immobile"        className={inputClassName(formik?.errors?.provincia_immobile)} />
                                                <ErrorMessage name="provincia_immobile" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Asta Diretta 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Dropdown  name="asta_diretta"     optionLabel="label" optionValue="value" value={formik.values.asta_diretta} onChange={formik.handleChange} options={app.menus.astaDiretta} label="Asta Diretta"  placeholder="Seleziona un valore ..."  className={inputClassName(formik?.errors?.asta_diretta)}   />
                                                <ErrorMessage name="asta_diretta" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Valore Aggiudicazione 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="valore_aggiudicazione"  onChange={formik.handleChange}  value={formik.values.valore_aggiudicazione}   label="Valore Aggiudicazione" type="number" placeholder="accedere Valore Aggiudicazione"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.valore_aggiudicazione)} />
                                                <ErrorMessage name="valore_aggiudicazione" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label="Aggiornare" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            );
                            }
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}
ImmobiliEditPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'immobili',
	apiPath: 'immobili/edit',
	routeName: 'immobiliedit',
	submitButtonLabel: "Aggiornare",
	formValidationError: "Il modulo non è valido",
	formValidationMsg: "Si prega di compilare il modulo",
	msgTitle: "Aggiorna registro",
	msgAfterSave: "Record modificato",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default ImmobiliEditPage;
