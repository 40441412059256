import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
const LeadsEditPage = (props) => {
		const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
		cognome: yup.string().required().label("Cognome"),
		nome: yup.string().required().label("Nome"),
		email: yup.string().email().nullable().label("Email"),
		telefono: yup.string().required().label("Telefono"),
		data_contatto: yup.string().required().label("Data Contatto"),
		nota: yup.string().nullable().label("Nota"),
		data_richiamo: yup.string().nullable().label("Data Richiamo"),
		pec: yup.string().nullable().label("Pec"),
		firma_digitale: yup.string().nullable().label("Firma Digitale"),
		stato_lead: yup.string().nullable().label("Stato Lead")
	});
	// form default values
	const formDefaultValues = {
		cognome: '', 
		nome: '', 
		email: '', 
		telefono: '', 
		data_contatto: new Date(), 
		nota: '', 
		data_richiamo: new Date(), 
		pec: '', 
		firma_digitale: '', 
		stato_lead: '', 
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/leads`);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="LeadsEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="modificare"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik
                            initialValues={formData}
                            validationSchema={validationSchema} 
                            onSubmit={(values, actions) => {
                            submitForm(values);
                            }
                            }
                            >
                            { (formik) => {
                            return (
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Cognome *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="cognome"  onChange={formik.handleChange}  value={formik.values.cognome}   label="Cognome" type="text" placeholder="accedere Cognome"        className={inputClassName(formik?.errors?.cognome)} />
                                                <ErrorMessage name="cognome" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Nome *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="nome"  onChange={formik.handleChange}  value={formik.values.nome}   label="Nome" type="text" placeholder="accedere Nome"        className={inputClassName(formik?.errors?.nome)} />
                                                <ErrorMessage name="nome" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Email 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="email"  onChange={formik.handleChange}  value={formik.values.email}   label="Email" type="email" placeholder="accedere Email"        className={inputClassName(formik?.errors?.email)} />
                                                <ErrorMessage name="email" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Telefono *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="telefono"  onChange={formik.handleChange}  value={formik.values.telefono}   label="Telefono" type="text" placeholder="accedere Telefono"        className={inputClassName(formik?.errors?.telefono)} />
                                                <ErrorMessage name="telefono" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Data Contatto *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Calendar name="data_contatto" showButtonBar className={inputClassName(formik?.errors?.data_contatto)} dateFormat="yy-mm-dd" value={formik.values.data_contatto} onChange={formik.handleChange} showIcon        />
                                                <ErrorMessage name="data_contatto" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Nota 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputTextarea name="nota"  className={inputClassName(formik?.errors?.nota)}   value={formik.values.nota} placeholder="accedere Nota" onChange={formik.handleChange}   >
                                                </InputTextarea>
                                                <ErrorMessage name="nota" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Data Richiamo 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Calendar name="data_richiamo" showButtonBar className={inputClassName(formik?.errors?.data_richiamo)} dateFormat="yy-mm-dd" value={formik.values.data_richiamo} onChange={formik.handleChange} showIcon        />
                                                <ErrorMessage name="data_richiamo" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Pec 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Dropdown  name="pec"     optionLabel="label" optionValue="value" value={formik.values.pec} onChange={formik.handleChange} options={app.menus.pec} label="Pec"  placeholder="Seleziona un valore ..."  className={inputClassName(formik?.errors?.pec)}   />
                                                <ErrorMessage name="pec" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Firma Digitale 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Dropdown  name="firma_digitale"     optionLabel="label" optionValue="value" value={formik.values.firma_digitale} onChange={formik.handleChange} options={app.menus.pec} label="Firma Digitale"  placeholder="Seleziona un valore ..."  className={inputClassName(formik?.errors?.firma_digitale)}   />
                                                <ErrorMessage name="firma_digitale" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Stato Lead 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Dropdown  name="stato_lead"     optionLabel="label" optionValue="value" value={formik.values.stato_lead} onChange={formik.handleChange} options={app.menus.statoLead} label="Stato Lead"  placeholder="Seleziona un valore ..."  className={inputClassName(formik?.errors?.stato_lead)}   />
                                                <ErrorMessage name="stato_lead" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label="Aggiornare" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            );
                            }
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}
LeadsEditPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'leads',
	apiPath: 'leads/edit',
	routeName: 'leadsedit',
	submitButtonLabel: "Aggiornare",
	formValidationError: "Il modulo non è valido",
	formValidationMsg: "Si prega di compilare il modulo",
	msgTitle: "Aggiorna registro",
	msgAfterSave: "Record modificato",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default LeadsEditPage;
