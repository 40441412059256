import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
const DatiomiEditPage = (props) => {
		const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
		regione: yup.string().required().label("Regione"),
		provincia: yup.string().required().label("Provincia"),
		codice_comune: yup.string().required().label("Codice Comune"),
		comune: yup.string().required().label("Comune"),
		fascia: yup.string().required().label("Fascia"),
		tipologia: yup.string().required().label("Tipologia"),
		prezzo_min: yup.number().required().label("Prezzo Min"),
		prezzo_max: yup.number().required().label("Prezzo Max")
	});
	// form default values
	const formDefaultValues = {
		regione: '', 
		provincia: '', 
		codice_comune: '', 
		comune: '', 
		fascia: '', 
		tipologia: '', 
		prezzo_min: '', 
		prezzo_max: '', 
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/datiomi`);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="DatiomiEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="modificare"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik
                            initialValues={formData}
                            validationSchema={validationSchema} 
                            onSubmit={(values, actions) => {
                            submitForm(values);
                            }
                            }
                            >
                            { (formik) => {
                            return (
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Regione *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="regione"  onChange={formik.handleChange}  value={formik.values.regione}   label="Regione" type="text" placeholder="accedere Regione"        className={inputClassName(formik?.errors?.regione)} />
                                                <ErrorMessage name="regione" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Provincia *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="provincia"  onChange={formik.handleChange}  value={formik.values.provincia}   label="Provincia" type="text" placeholder="accedere Provincia"        className={inputClassName(formik?.errors?.provincia)} />
                                                <ErrorMessage name="provincia" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Codice Comune *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="codice_comune"  onChange={formik.handleChange}  value={formik.values.codice_comune}   label="Codice Comune" type="text" placeholder="accedere Codice Comune"        className={inputClassName(formik?.errors?.codice_comune)} />
                                                <ErrorMessage name="codice_comune" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Comune *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="comune"  onChange={formik.handleChange}  value={formik.values.comune}   label="Comune" type="text" placeholder="accedere Comune"        className={inputClassName(formik?.errors?.comune)} />
                                                <ErrorMessage name="comune" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Fascia *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="fascia"  onChange={formik.handleChange}  value={formik.values.fascia}   label="Fascia" type="text" placeholder="accedere Fascia"        className={inputClassName(formik?.errors?.fascia)} />
                                                <ErrorMessage name="fascia" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Tipologia *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="tipologia"  onChange={formik.handleChange}  value={formik.values.tipologia}   label="Tipologia" type="text" placeholder="accedere Tipologia"        className={inputClassName(formik?.errors?.tipologia)} />
                                                <ErrorMessage name="tipologia" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Prezzo Min *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="prezzo_min"  onChange={formik.handleChange}  value={formik.values.prezzo_min}   label="Prezzo Min" type="number" placeholder="accedere Prezzo Min"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.prezzo_min)} />
                                                <ErrorMessage name="prezzo_min" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Prezzo Max *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="prezzo_max"  onChange={formik.handleChange}  value={formik.values.prezzo_max}   label="Prezzo Max" type="number" placeholder="accedere Prezzo Max"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.prezzo_max)} />
                                                <ErrorMessage name="prezzo_max" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label="Update" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            );
                            }
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}
DatiomiEditPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'datiomi',
	apiPath: 'datiomi/edit',
	routeName: 'datiomiedit',
	submitButtonLabel: "Update",
	formValidationError: "Il modulo non è valido",
	formValidationMsg: "Si prega di compilare il modulo",
	msgTitle: "Aggiorna registro",
	msgAfterSave: "Record modificato",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default DatiomiEditPage;
