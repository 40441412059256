import { useState } from 'react';
import { RecordCount } from 'components/RecordCount';
import { Title } from 'components/Title';

export default function HomePage() {
	
	const [pageReady, setPageReady] = useState(true);
	return (
		<main id="HomePage" className="main-page">
<section className="page-section q-pa-md" >
    <div className="container-fluid">
        <div className="grid ">
            <div className="col comp-grid" >
                <Title title="Home"   titleClass="text-lg font-bold text-primary" subTitleClass="text-500"      separator={false} />
            </div>
        </div>
    </div>
</section>
<section className="page-section mb-3" >
    <div className="container-fluid">
        <div className="grid ">
            <div className="col-4 comp-grid" >
                <RecordCount title="Immobili n.ro" description="Totale degli immobili" icon="pi pi-th-large" apiPath="components_data/getcount_immobilinro" link={`/immobili`} cardClass="bg-blue-100 text-blue-800" avatarClass="bg-blue-600 text-blue-100"   />
            </div>
            <div className="col-4 comp-grid" >
                <RecordCount title="Next 30" description="Immobili in asta entro 30 gg" icon="pi pi-clock" apiPath="components_data/getcount_next30" link={`/immobili/list30`} cardClass="bg-blue-100 text-blue-800" avatarClass="bg-blue-600 text-blue-100"   />
            </div>
            <div className="col-4 comp-grid" >
                <RecordCount title="Next 10" description="Immobili in scadenza 10 gg" icon="pi pi-clock" apiPath="components_data/getcount_next10" link={`/immobili/list7`} cardClass="text-blue-800 bg-orange-400" avatarClass="bg-blue-600 text-blue-100"   />
            </div>
        </div>
    </div>
</section>
		</main>
	);
}
