import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { Column } from 'primereact/column';
import { DataSource } from 'components/DataSource';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { FilterTags } from 'components/FilterTags';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { PageRequestError } from 'components/PageRequestError';
import { Paginator } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';
import useUtils from 'hooks/useUtils';

import useListPage from 'hooks/useListPage';
const DatiomiListPage = (props) => {
		const app = useApp();
	const utils = useUtils();
	const filterSchema = {
		search: {
			tagTitle: "Search",
			value: '',
			valueType: 'single',
			options: [],
		},
		regione: {
			tagTitle: "Regione",
			value: '',
			valueType: 'single',
			options: [],
		},
		provincia: {
			tagTitle: "Provincia",
			value: '',
			valueType: 'single',
			options: [],
		},
		comune: {
			tagTitle: "Comune",
			value: '',
			valueType: 'single',
			options: [],
		},
		fascia: {
			tagTitle: "Fascia",
			value: '',
			valueType: 'single',
			options: [],
		},
		tipologia: {
			tagTitle: "Tipologia",
			value: '',
			valueType: 'single',
			options: [],
		}
	}
	const pageController = useListPage(props, filterSchema);
	const filterController = pageController.filterController;
	const { records, pageReady, loading, selectedItems, sortBy, sortOrder, apiRequestError, setSelectedItems, getPageBreadCrumbs, onSort, deleteItem, pagination } = pageController;
	const { filters, setFilterValue, setFilterOptions } = filterController;
	const { totalRecords, totalPages, recordsPosition, firstRow, limit, onPageChange } =  pagination;
	// return average of prezzo_min for the current records
	const avgPrezzoMin = utils.average(records, 'prezzo_min');
	// return average of prezzo_max for the current records
	const avgPrezzoMax = utils.average(records, 'prezzo_max');
	function ActionButton(data){
		const items = [
		{
			label: "View",
			command: (event) => { app.navigate(`/datiomi/view/${data.id}`) },
			icon: "pi pi-eye"
		},
		{
			label: "Edit",
			command: (event) => { app.navigate(`/datiomi/edit/${data.id}`) },
			icon: "pi pi-pencil"
		},
		{
			label: "Delete",
			command: (event) => { deleteItem(data.id) },
			icon: "pi pi-trash"
		}
	]
		return (<SplitButton dropdownIcon="pi pi-bars" className="dropdown-only p-button-text p-button-plain" model={items} />);
	}
	function IdTemplate(data){
		if(data){
			return (
				<Link to={`/datiomi/view/${data.id}`}> { data.id }</Link>
			);
		}
	}
	function PageLoading(){
		if(loading){
			return (
				<>
					<div className="flex align-items-center justify-content-center text-gray-500 p-3">
						<div><ProgressSpinner style={{width:'30px', height:'30px'}} /> </div>
						<div  className="font-bold text-lg">Caricamento in corso...</div>
					</div>
				</>
			);
		}
	}
	function EmptyRecordMessage(){
		if(pageReady && !records.length){
			return (
				<div className="text-lg mt-3 p-3 text-center text-400 font-bold">
					No record found
				</div>
			);
		}
	}
	function MultiDelete() {
		if (selectedItems.length) {
			return (
				<div className="m-2 flex-grow-0">
					<Button onClick={() => deleteItem(selectedItems)} icon="pi pi-trash" className="p-button-danger" title="Elimina selezionato"/>
				</div>
			)
		}
	}
	function PagerControl() {
		if (props.paginate && totalPages > 1) {
		const pagerReportTemplate = {
			layout: pagination.layout,
			CurrentPageReport: (options) => {
				return (
					<>
						<span className="text-sm text-gray-500 px-2">Record <b>{ recordsPosition } di { options.totalRecords }</b></span>
					</>
				);
			}
		}
		return (
			<div className="flex-grow-1">
				<Paginator first={firstRow} rows={limit} totalRecords={totalRecords}  onPageChange={onPageChange} template={pagerReportTemplate} />
			</div>
		)
		}
	}
	function PageActionButtons() {
		return (
			<div className="flex flex-wrap">
				<MultiDelete />
			</div>
		);
	}
	function PageFooter() {
		if (pageReady && props.showFooter) {
			return (
				<div className="flex flex-wrap">
					<PageActionButtons />
					<PagerControl />
				</div>
			);
		}
	}
	function PageBreadcrumbs(){
		if(props.showBreadcrumbs) {
			const items = getPageBreadCrumbs();
			return (items.length > 0 && <BreadCrumb className="mb-3" model={items} />);
		}
	}
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	return (
<main id="DatiomiListPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container-fluid">
            <div className="grid justify-content-between align-items-center">
                <div className="col " >
                    <Title title="Dati Omi"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
                <div className="col-fixed " >
                    <Link to={`/datiomi/add`}>
                        <Button label="Aggiungere nuova" icon="pi pi-plus" type="button" className="p-button w-full bg-primary "  />
                        </Link>
                    </div>
                    <div className="col-12 md:col-3 " >
                        <span className="p-input-icon-left w-full">
                        <i className="pi pi-search" />
                        <InputText placeholder="Ricerca" className="w-full" value={filters.search.value}  onChange={(e) => setFilterValue('search', e.target.value)} />
                        </span>
                    </div>
                </div>
            </div>
        </section>
        }
        <section className="page-section " >
            <div className="container-fluid">
                <div className="grid ">
                    <div className="col-2 comp-grid" >
                        <div className="card  sp-3 mb-3">
                            <DataSource onLoad={(options) => setFilterOptions('regione', options)}  apiPath="components_data/regione_option_list"  >
                                {
                                ({ response }) => 
                                <>
                                <Title title="Regione"  headerClass="p-3" titleClass="font-bold text-primary" subTitleClass="text-sm text-500"      separator={false} />
                                <div className="">
                                    <Dropdown filter={true} className="w-full" onChange={(e) => setFilterValue( 'regione', e.value)} value={filters.regione.value} optionLabel="label" optionValue="value" options={filters.regione.options} placeholder="Seleziona un valore ..." >
                                    </Dropdown>
                                </div>
                                </>
                                }
                            </DataSource>
                        </div>
                        <div className="card  sp-3 mb-3">
                            <DataSource onLoad={(options) => setFilterOptions('provincia', options)}  apiPath="components_data/provincia_option_list"  >
                                {
                                ({ response }) => 
                                <>
                                <Title title="Provincia"  headerClass="p-3" titleClass="font-bold text-primary" subTitleClass="text-sm text-500"      separator={false} />
                                <div className="">
                                    <Dropdown filter={true} className="w-full" onChange={(e) => setFilterValue( 'provincia', e.value)} value={filters.provincia.value} optionLabel="label" optionValue="value" options={filters.provincia.options} placeholder="Seleziona un valore ..." >
                                    </Dropdown>
                                </div>
                                </>
                                }
                            </DataSource>
                        </div>
                        <div className="card  sp-3 mb-3">
                            <DataSource onLoad={(options) => setFilterOptions('comune', options)}  apiPath="components_data/comune_option_list"  >
                                {
                                ({ response }) => 
                                <>
                                <Title title="Comune"  headerClass="p-3" titleClass="font-bold text-primary" subTitleClass="text-sm text-500"      separator={false} />
                                <div className="">
                                    <Dropdown filter={true} className="w-full" onChange={(e) => setFilterValue( 'comune', e.value)} value={filters.comune.value} optionLabel="label" optionValue="value" options={filters.comune.options} placeholder="Seleziona un valore ..." >
                                    </Dropdown>
                                </div>
                                </>
                                }
                            </DataSource>
                        </div>
                        <div className="card  sp-3 mb-3">
                            <DataSource onLoad={(options) => setFilterOptions('fascia', options)}  apiPath="components_data/fascia_option_list"  >
                                {
                                ({ response }) => 
                                <>
                                <Title title="Fascia"  headerClass="p-3" titleClass="font-bold text-primary" subTitleClass="text-sm text-500"      separator={false} />
                                <div className="">
                                    <Dropdown filter={true} className="w-full" onChange={(e) => setFilterValue( 'fascia', e.value)} value={filters.fascia.value} optionLabel="label" optionValue="value" options={filters.fascia.options} placeholder="Seleziona un valore ..." >
                                    </Dropdown>
                                </div>
                                </>
                                }
                            </DataSource>
                        </div>
                        <div className="card  sp-3 mb-3">
                            <DataSource onLoad={(options) => setFilterOptions('tipologia', options)}  apiPath="components_data/tipologia_option_list"  >
                                {
                                ({ response }) => 
                                <>
                                <Title title="Tipologia"  headerClass="p-3" titleClass="font-bold text-primary" subTitleClass="text-sm text-500"      separator={false} />
                                <div className="">
                                    <Dropdown filter={true} className="w-full" onChange={(e) => setFilterValue( 'tipologia', e.value)} value={filters.tipologia.value} optionLabel="label" optionValue="value" options={filters.tipologia.options} placeholder="Seleziona un valore ..." >
                                    </Dropdown>
                                </div>
                                </>
                                }
                            </DataSource>
                        </div>
                    </div>
                    <div className="col-10 comp-grid" >
                        <FilterTags filterController={filterController} />
                        <div >
                            <PageBreadcrumbs />
                            <div className="page-records">
                                <DataTable 
                                    lazy={true} 
                                    loading={loading} 
                                    selectionMode="checkbox" selection={selectedItems} onSelectionChange={e => setSelectedItems(e.value)}
                                    value={records} 
                                    dataKey="id" 
                                    sortField={sortBy} 
                                    sortOrder={sortOrder} 
                                    onSort={onSort}
                                    className=" p-datatable-sm" 
                                    stripedRows={true}
                                    showGridlines={false} 
                                    rowHover={true} 
                                    responsiveLayout="stack" 
                                    emptyMessage={<EmptyRecordMessage />} 
                                    >
                                    {/*PageComponentStart*/}
                                    <Column selectionMode="multiple" headerStyle={{width: '2rem'}}></Column>
                                    <Column  field="id" header="Id" body={IdTemplate}  ></Column>
                                    <Column  field="regione" header="Regione"   ></Column>
                                    <Column  field="provincia" header="Provincia"   ></Column>
                                    <Column  field="codice_comune" header="Codice Comune"   ></Column>
                                    <Column  field="comune" header="Comune"   ></Column>
                                    <Column  field="fascia" header="Fascia"   ></Column>
                                    <Column  field="tipologia" header="Tipologia"   ></Column>
                                    <Column  field="prezzo_min" header="Prezzo Min"   ></Column>
                                    <Column  field="prezzo_max" header="Prezzo Max"   ></Column>
                                    <Column headerStyle={{width: '2rem'}} headerClass="text-center" body={ActionButton}></Column>
                                    {/*PageComponentEnd*/}
                                </DataTable>
                                <div className="flex justify-content-around font-bold p-3">
                                    <div>Prezzo Medio Min : <Chip label={avgPrezzoMin} /></div><div>Prezzo Medio Max : <Chip label={avgPrezzoMax} /></div>
                                </div>
                            </div>
                            <PageFooter />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
	);
}
DatiomiListPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'datiomi',
	apiPath: 'datiomi/index',
	routeName: 'datiomilist',
	msgBeforeDelete: "Sei sicuro di voler eliminare questo elemento?",
	msgTitle: "Elimina record",
	msgAfterDelete: "Record cancellato",
	showHeader: true,
	showFooter: true,
	paginate: true,
	isSubPage: false,
	showBreadcrumbs: true,
	exportData: false,
	importData: false,
	keepRecords: false,
	multiCheckbox: true,
	search: '',
	fieldName: null,
	fieldValue: null,
	sortField: '',
	sortDir: '',
	pageNo: 1,
	limit: 10,
}
export default DatiomiListPage;
