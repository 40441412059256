import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
const LeadsagenzieEditStatistichePage = (props) => {
		const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
		monolocale_c_ag: yup.number().nullable().label("<b>Monolocale</b> Centro Agenzia"),
		monolocale_c_ade: yup.number().nullable().label("Centro Ag. Entrate"),
		monolocale_s_ag: yup.number().nullable().label("SemiCentro Agenzia"),
		monolocale_s_ade: yup.number().nullable().label("SemiCentro Ag. Entrate"),
		monolocale_p_ag: yup.number().nullable().label("Periferia Agenzia"),
		monolocale_p_ade: yup.number().nullable().label("Periferia Ag. Entrate"),
		bilocale_c_ag: yup.number().nullable().label("<b>Bilocale</b> Centro Agenzia"),
		bilocale_c_ade: yup.number().nullable().label("Centro Ag. Entrate"),
		bilocale_s_ag: yup.number().nullable().label("SemiCentro Agenzia"),
		bilocale_s_ade: yup.number().nullable().label("SemiCentro Ag.Entrate"),
		bilocale_p_ag: yup.number().nullable().label("Periferia Agenzia"),
		bilocale_p_ade: yup.number().nullable().label("Periferia Ag. Entrate"),
		trilocale_c_ag: yup.number().nullable().label("<b>Trilocale</b> Centro Agenzia"),
		trilocale_c_ade: yup.number().nullable().label("Centro Ag.entrate"),
		trilocale_s_ag: yup.number().nullable().label("SemiCentro Agenzia"),
		trilocale_s_ade: yup.number().nullable().label("SemiCentro Ag.Entrate"),
		trilocale_p_ag: yup.number().nullable().label("Periferia Agenzia"),
		trilocale_p_ade: yup.number().nullable().label("Periferia Ag.Entrate"),
		quadrilocale_c_ag: yup.number().nullable().label("<b>Quadrilocale</b> Centro Agenzia"),
		quadrilocale_c_ade: yup.number().nullable().label("Centro Ag.Entrate"),
		quadrilocale_s_ag: yup.number().nullable().label("SemiCentro Agenzia"),
		quadrilocale_s_ade: yup.number().nullable().label("SemiCentro Ag.Entrate"),
		quadrilocale_p_ag: yup.number().nullable().label("Periferia Agenzia"),
		quadrilocale_p_ade: yup.number().nullable().label("Periferia Ag.Entrate"),
		semindipendenti_c_ag: yup.number().nullable().label("<b>Semindipen.</b>Centro Agenzia"),
		semindipendenti_c_ade: yup.number().nullable().label("Centro Ag.Entrate"),
		semindipendenti_s_ag: yup.number().nullable().label("SemiCentro Agenzia"),
		semindipendenti_s_ade: yup.number().nullable().label("SemiCentro Ag.Entrate"),
		semindipendenti_p_ag: yup.number().nullable().label("Periferia Agenzia"),
		semindipendenti_p_ade: yup.number().nullable().label("Periferia Ag. Entrate"),
		indipendenti_c_ag: yup.number().nullable().label("<b>Indipendenti</b> Centro Agenzia"),
		indipendenti_c_ade: yup.number().nullable().label("Centro Ag.Entrate"),
		indipendenti_s_ag: yup.number().nullable().label("SemiCentro Agenzia"),
		indipendenti_s_ade: yup.number().nullable().label("SemiCentro Ag.Entrate"),
		indipendenti_p_ag: yup.number().nullable().label("Periferia Agenzia"),
		indipendenti_p_ade: yup.number().nullable().label("Periferia Ag.Entrate"),
		negozi_c_ag: yup.number().nullable().label("<b>Negozi</b> Centro Agenzia"),
		negozi_c_ade: yup.number().nullable().label("Centro Ag.Entrate"),
		negozi_s_ag: yup.number().nullable().label("SemiCentro Agenzia"),
		negozi_s_ade: yup.number().nullable().label("Semicentro Ag.Entrate"),
		negozi_p_ag: yup.number().nullable().label("Periferia Agenzia"),
		negozi_p_ade: yup.number().nullable().label("Periferia Ag.Entrate"),
		uffici_c_ag: yup.number().nullable().label("<b>Uffici</b> Centro Agenzia"),
		uffici_c_ade: yup.number().nullable().label("Centro Ag.Entrate"),
		uffici_s_ag: yup.number().nullable().label("SemiCentro Agenzia"),
		uffici_s_ade: yup.number().nullable().label("SemiCentro Ag.Entrate"),
		uffici_p_ag: yup.number().nullable().label("Periferia Agenzia"),
		uffici_p_ade: yup.number().nullable().label("Periferia Ag.Entrate"),
		terreni_agricoli_c_ag: yup.number().nullable().label("<b>Terreni Agricoli</b> Centro Agenzia"),
		terreni_agricoli_c_ade: yup.number().nullable().label("Terreni Agricoli Ag.Entrate"),
		terreni_agricoli_s_ag: yup.number().nullable().label("SemiCentro Agenzia"),
		terreni_agricoli_s_ade: yup.number().nullable().label("SemiCentro Ag.Entrate"),
		terreni_agricoli_p_ag: yup.number().nullable().label("Periferia agenzia"),
		terreni_agricoli_p_ade: yup.number().nullable().label("Periferia Ag.Entrate"),
		terreni_edificabili_c_ag: yup.number().nullable().label("<b>Terreni Edificabili</b> Centro Agenzia"),
		terreni_edificabili_c_ade: yup.number().nullable().label("Centro Ag.Entrate"),
		terreni_edificabili_s_ag: yup.number().nullable().label("SemiCentro Agenzia"),
		terreni_edificabili_s_ade: yup.number().nullable().label("SemiCentro Ag.Entrate"),
		terreni_edificabili_p_ag: yup.number().nullable().label("Periferia Agenzia"),
		terreni_edificabili_p_ade: yup.number().nullable().label("Periferia Ag.Entrate"),
		capannoni_c_ag: yup.number().nullable().label("<b>Capannoni</b> Centro Agenzia"),
		capannoni_c_ade: yup.number().nullable().label("Centro Ag.Entrate"),
		capannoni_s_ag: yup.number().nullable().label("SemiCentro Agenzia"),
		capannoni_s_ade: yup.number().nullable().label("SemiCentro Ag.Entrate"),
		capannoni_p_ag: yup.number().nullable().label("Periferia Agenzia"),
		capannoni_p_ade: yup.number().nullable().label("Perifiche Ag.Entrate")
	});
	// form default values
	const formDefaultValues = {
		monolocale_c_ag: '', 
		monolocale_c_ade: '', 
		monolocale_s_ag: '', 
		monolocale_s_ade: '', 
		monolocale_p_ag: '', 
		monolocale_p_ade: '', 
		bilocale_c_ag: "NULL", 
		bilocale_c_ade: "NULL", 
		bilocale_s_ag: "NULL", 
		bilocale_s_ade: "NULL", 
		bilocale_p_ag: "NULL", 
		bilocale_p_ade: "NULL", 
		trilocale_c_ag: '', 
		trilocale_c_ade: '', 
		trilocale_s_ag: '', 
		trilocale_s_ade: '', 
		trilocale_p_ag: '', 
		trilocale_p_ade: '', 
		quadrilocale_c_ag: '', 
		quadrilocale_c_ade: '', 
		quadrilocale_s_ag: '', 
		quadrilocale_s_ade: '', 
		quadrilocale_p_ag: '', 
		quadrilocale_p_ade: '', 
		semindipendenti_c_ag: '', 
		semindipendenti_c_ade: '', 
		semindipendenti_s_ag: '', 
		semindipendenti_s_ade: '', 
		semindipendenti_p_ag: '', 
		semindipendenti_p_ade: '', 
		indipendenti_c_ag: '', 
		indipendenti_c_ade: '', 
		indipendenti_s_ag: '', 
		indipendenti_s_ade: '', 
		indipendenti_p_ag: '', 
		indipendenti_p_ade: '', 
		negozi_c_ag: '', 
		negozi_c_ade: '', 
		negozi_s_ag: '', 
		negozi_s_ade: '', 
		negozi_p_ag: '', 
		negozi_p_ade: '', 
		uffici_c_ag: '', 
		uffici_c_ade: '', 
		uffici_s_ag: '', 
		uffici_s_ade: '', 
		uffici_p_ag: '', 
		uffici_p_ade: '', 
		terreni_agricoli_c_ag: '', 
		terreni_agricoli_c_ade: '', 
		terreni_agricoli_s_ag: '', 
		terreni_agricoli_s_ade: '', 
		terreni_agricoli_p_ag: '', 
		terreni_agricoli_p_ade: '', 
		terreni_edificabili_c_ag: '', 
		terreni_edificabili_c_ade: '', 
		terreni_edificabili_s_ag: '', 
		terreni_edificabili_s_ade: '', 
		terreni_edificabili_p_ag: '', 
		terreni_edificabili_p_ade: '', 
		capannoni_c_ag: '', 
		capannoni_c_ade: '', 
		capannoni_s_ag: '', 
		capannoni_s_ade: '', 
		capannoni_p_ag: '', 
		capannoni_p_ade: '', 
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`//leadsagenzie`);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="LeadsagenzieEditStatistichePage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container-fluid">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-12 " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Dati statistiche Agenzia"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container-fluid">
            <div className="grid ">
                <div className="col-12 sm:col-12 md:col-12 comp-grid" >
                    <div >
                        <Formik
                            initialValues={formData}
                            validationSchema={validationSchema} 
                            onSubmit={(values, actions) => {
                            submitForm(values);
                            }
                            }
                            >
                            { (formik) => {
                            return (
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="md:col-2 col-12">
                                        <b>Monolocale</b> Centro Agenzia 
                                        <div> 
                                            <InputText name="monolocale_c_ag"  onChange={formik.handleChange}  value={formik.values.monolocale_c_ag}   label="<b>Monolocale</b> Centro Agenzia" type="number" placeholder="valore"  min={0}  step={0.01}    className={inputClassName(formik?.errors?.monolocale_c_ag)} />
                                            <ErrorMessage name="monolocale_c_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Centro Ag. Entrate 
                                        <div> 
                                            <InputText name="monolocale_c_ade"  onChange={formik.handleChange}  value={formik.values.monolocale_c_ade}   label="Centro Ag. Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.monolocale_c_ade)} />
                                            <ErrorMessage name="monolocale_c_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        SemiCentro Agenzia 
                                        <div> 
                                            <InputText name="monolocale_s_ag"  onChange={formik.handleChange}  value={formik.values.monolocale_s_ag}   label="SemiCentro Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.monolocale_s_ag)} />
                                            <ErrorMessage name="monolocale_s_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        SemiCentro Ag. Entrate 
                                        <div> 
                                            <InputText name="monolocale_s_ade"  onChange={formik.handleChange}  value={formik.values.monolocale_s_ade}   label="SemiCentro Ag. Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.monolocale_s_ade)} />
                                            <ErrorMessage name="monolocale_s_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Periferia Agenzia 
                                        <div> 
                                            <InputText name="monolocale_p_ag"  onChange={formik.handleChange}  value={formik.values.monolocale_p_ag}   label="Periferia Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.monolocale_p_ag)} />
                                            <ErrorMessage name="monolocale_p_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Periferia Ag. Entrate 
                                        <div> 
                                            <InputText name="monolocale_p_ade"  onChange={formik.handleChange}  value={formik.values.monolocale_p_ade}   label="Periferia Ag. Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.monolocale_p_ade)} />
                                            <ErrorMessage name="monolocale_p_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        <b>Bilocale</b> Centro Agenzia 
                                        <div> 
                                            <InputText name="bilocale_c_ag"  onChange={formik.handleChange}  value={formik.values.bilocale_c_ag}   label="<b>Bilocale</b> Centro Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.bilocale_c_ag)} />
                                            <ErrorMessage name="bilocale_c_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Centro Ag. Entrate 
                                        <div> 
                                            <InputText name="bilocale_c_ade"  onChange={formik.handleChange}  value={formik.values.bilocale_c_ade}   label="Centro Ag. Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.bilocale_c_ade)} />
                                            <ErrorMessage name="bilocale_c_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        SemiCentro Agenzia 
                                        <div> 
                                            <InputText name="bilocale_s_ag"  onChange={formik.handleChange}  value={formik.values.bilocale_s_ag}   label="SemiCentro Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.bilocale_s_ag)} />
                                            <ErrorMessage name="bilocale_s_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        SemiCentro Ag.Entrate 
                                        <div> 
                                            <InputText name="bilocale_s_ade"  onChange={formik.handleChange}  value={formik.values.bilocale_s_ade}   label="SemiCentro Ag.Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.bilocale_s_ade)} />
                                            <ErrorMessage name="bilocale_s_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Periferia Agenzia 
                                        <div> 
                                            <InputText name="bilocale_p_ag"  onChange={formik.handleChange}  value={formik.values.bilocale_p_ag}   label="Periferia Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.bilocale_p_ag)} />
                                            <ErrorMessage name="bilocale_p_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Periferia Ag. Entrate 
                                        <div> 
                                            <InputText name="bilocale_p_ade"  onChange={formik.handleChange}  value={formik.values.bilocale_p_ade}   label="Periferia Ag. Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.bilocale_p_ade)} />
                                            <ErrorMessage name="bilocale_p_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        <b>Trilocale</b> Centro Agenzia 
                                        <div> 
                                            <InputText name="trilocale_c_ag"  onChange={formik.handleChange}  value={formik.values.trilocale_c_ag}   label="<b>Trilocale</b> Centro Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.trilocale_c_ag)} />
                                            <ErrorMessage name="trilocale_c_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Centro Ag.entrate 
                                        <div> 
                                            <InputText name="trilocale_c_ade"  onChange={formik.handleChange}  value={formik.values.trilocale_c_ade}   label="Centro Ag.entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.trilocale_c_ade)} />
                                            <ErrorMessage name="trilocale_c_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        SemiCentro Agenzia 
                                        <div> 
                                            <InputText name="trilocale_s_ag"  onChange={formik.handleChange}  value={formik.values.trilocale_s_ag}   label="SemiCentro Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.trilocale_s_ag)} />
                                            <ErrorMessage name="trilocale_s_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        SemiCentro Ag.Entrate 
                                        <div> 
                                            <InputText name="trilocale_s_ade"  onChange={formik.handleChange}  value={formik.values.trilocale_s_ade}   label="SemiCentro Ag.Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.trilocale_s_ade)} />
                                            <ErrorMessage name="trilocale_s_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Periferia Agenzia 
                                        <div> 
                                            <InputText name="trilocale_p_ag"  onChange={formik.handleChange}  value={formik.values.trilocale_p_ag}   label="Periferia Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.trilocale_p_ag)} />
                                            <ErrorMessage name="trilocale_p_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Periferia Ag.Entrate 
                                        <div> 
                                            <InputText name="trilocale_p_ade"  onChange={formik.handleChange}  value={formik.values.trilocale_p_ade}   label="Periferia Ag.Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.trilocale_p_ade)} />
                                            <ErrorMessage name="trilocale_p_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        <b>Quadrilocale</b> Centro Agenzia 
                                        <div> 
                                            <InputText name="quadrilocale_c_ag"  onChange={formik.handleChange}  value={formik.values.quadrilocale_c_ag}   label="<b>Quadrilocale</b> Centro Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.quadrilocale_c_ag)} />
                                            <ErrorMessage name="quadrilocale_c_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Centro Ag.Entrate 
                                        <div> 
                                            <InputText name="quadrilocale_c_ade"  onChange={formik.handleChange}  value={formik.values.quadrilocale_c_ade}   label="Centro Ag.Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.quadrilocale_c_ade)} />
                                            <ErrorMessage name="quadrilocale_c_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        SemiCentro Agenzia 
                                        <div> 
                                            <InputText name="quadrilocale_s_ag"  onChange={formik.handleChange}  value={formik.values.quadrilocale_s_ag}   label="SemiCentro Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.quadrilocale_s_ag)} />
                                            <ErrorMessage name="quadrilocale_s_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        SemiCentro Ag.Entrate 
                                        <div> 
                                            <InputText name="quadrilocale_s_ade"  onChange={formik.handleChange}  value={formik.values.quadrilocale_s_ade}   label="SemiCentro Ag.Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.quadrilocale_s_ade)} />
                                            <ErrorMessage name="quadrilocale_s_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Periferia Agenzia 
                                        <div> 
                                            <InputText name="quadrilocale_p_ag"  onChange={formik.handleChange}  value={formik.values.quadrilocale_p_ag}   label="Periferia Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.quadrilocale_p_ag)} />
                                            <ErrorMessage name="quadrilocale_p_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Periferia Ag.Entrate 
                                        <div> 
                                            <InputText name="quadrilocale_p_ade"  onChange={formik.handleChange}  value={formik.values.quadrilocale_p_ade}   label="Periferia Ag.Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.quadrilocale_p_ade)} />
                                            <ErrorMessage name="quadrilocale_p_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        <b>Semindipen.</b>Centro Agenzia 
                                        <div> 
                                            <InputText name="semindipendenti_c_ag"  onChange={formik.handleChange}  value={formik.values.semindipendenti_c_ag}   label="<b>Semindipen.</b>Centro Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.semindipendenti_c_ag)} />
                                            <ErrorMessage name="semindipendenti_c_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Centro Ag.Entrate 
                                        <div> 
                                            <InputText name="semindipendenti_c_ade"  onChange={formik.handleChange}  value={formik.values.semindipendenti_c_ade}   label="Centro Ag.Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.semindipendenti_c_ade)} />
                                            <ErrorMessage name="semindipendenti_c_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        SemiCentro Agenzia 
                                        <div> 
                                            <InputText name="semindipendenti_s_ag"  onChange={formik.handleChange}  value={formik.values.semindipendenti_s_ag}   label="SemiCentro Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.semindipendenti_s_ag)} />
                                            <ErrorMessage name="semindipendenti_s_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        SemiCentro Ag.Entrate 
                                        <div> 
                                            <InputText name="semindipendenti_s_ade"  onChange={formik.handleChange}  value={formik.values.semindipendenti_s_ade}   label="SemiCentro Ag.Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.semindipendenti_s_ade)} />
                                            <ErrorMessage name="semindipendenti_s_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Periferia Agenzia 
                                        <div> 
                                            <InputText name="semindipendenti_p_ag"  onChange={formik.handleChange}  value={formik.values.semindipendenti_p_ag}   label="Periferia Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.semindipendenti_p_ag)} />
                                            <ErrorMessage name="semindipendenti_p_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Periferia Ag. Entrate 
                                        <div> 
                                            <InputText name="semindipendenti_p_ade"  onChange={formik.handleChange}  value={formik.values.semindipendenti_p_ade}   label="Periferia Ag. Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.semindipendenti_p_ade)} />
                                            <ErrorMessage name="semindipendenti_p_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        <b>Indipendenti</b> Centro Agenzia 
                                        <div> 
                                            <InputText name="indipendenti_c_ag"  onChange={formik.handleChange}  value={formik.values.indipendenti_c_ag}   label="<b>Indipendenti</b> Centro Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.indipendenti_c_ag)} />
                                            <ErrorMessage name="indipendenti_c_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Centro Ag.Entrate 
                                        <div> 
                                            <InputText name="indipendenti_c_ade"  onChange={formik.handleChange}  value={formik.values.indipendenti_c_ade}   label="Centro Ag.Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.indipendenti_c_ade)} />
                                            <ErrorMessage name="indipendenti_c_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        SemiCentro Agenzia 
                                        <div> 
                                            <InputText name="indipendenti_s_ag"  onChange={formik.handleChange}  value={formik.values.indipendenti_s_ag}   label="SemiCentro Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.indipendenti_s_ag)} />
                                            <ErrorMessage name="indipendenti_s_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        SemiCentro Ag.Entrate 
                                        <div> 
                                            <InputText name="indipendenti_s_ade"  onChange={formik.handleChange}  value={formik.values.indipendenti_s_ade}   label="SemiCentro Ag.Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.indipendenti_s_ade)} />
                                            <ErrorMessage name="indipendenti_s_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Periferia Agenzia 
                                        <div> 
                                            <InputText name="indipendenti_p_ag"  onChange={formik.handleChange}  value={formik.values.indipendenti_p_ag}   label="Periferia Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.indipendenti_p_ag)} />
                                            <ErrorMessage name="indipendenti_p_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Periferia Ag.Entrate 
                                        <div> 
                                            <InputText name="indipendenti_p_ade"  onChange={formik.handleChange}  value={formik.values.indipendenti_p_ade}   label="Periferia Ag.Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.indipendenti_p_ade)} />
                                            <ErrorMessage name="indipendenti_p_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        <b>Negozi</b> Centro Agenzia 
                                        <div> 
                                            <InputText name="negozi_c_ag"  onChange={formik.handleChange}  value={formik.values.negozi_c_ag}   label="<b>Negozi</b> Centro Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.negozi_c_ag)} />
                                            <ErrorMessage name="negozi_c_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Centro Ag.Entrate 
                                        <div> 
                                            <InputText name="negozi_c_ade"  onChange={formik.handleChange}  value={formik.values.negozi_c_ade}   label="Centro Ag.Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.negozi_c_ade)} />
                                            <ErrorMessage name="negozi_c_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        SemiCentro Agenzia 
                                        <div> 
                                            <InputText name="negozi_s_ag"  onChange={formik.handleChange}  value={formik.values.negozi_s_ag}   label="SemiCentro Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.negozi_s_ag)} />
                                            <ErrorMessage name="negozi_s_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Semicentro Ag.Entrate 
                                        <div> 
                                            <InputText name="negozi_s_ade"  onChange={formik.handleChange}  value={formik.values.negozi_s_ade}   label="Semicentro Ag.Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.negozi_s_ade)} />
                                            <ErrorMessage name="negozi_s_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Periferia Agenzia 
                                        <div> 
                                            <InputText name="negozi_p_ag"  onChange={formik.handleChange}  value={formik.values.negozi_p_ag}   label="Periferia Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.negozi_p_ag)} />
                                            <ErrorMessage name="negozi_p_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Periferia Ag.Entrate 
                                        <div> 
                                            <InputText name="negozi_p_ade"  onChange={formik.handleChange}  value={formik.values.negozi_p_ade}   label="Periferia Ag.Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.negozi_p_ade)} />
                                            <ErrorMessage name="negozi_p_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        <b>Uffici</b> Centro Agenzia 
                                        <div> 
                                            <InputText name="uffici_c_ag"  onChange={formik.handleChange}  value={formik.values.uffici_c_ag}   label="<b>Uffici</b> Centro Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.uffici_c_ag)} />
                                            <ErrorMessage name="uffici_c_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Centro Ag.Entrate 
                                        <div> 
                                            <InputText name="uffici_c_ade"  onChange={formik.handleChange}  value={formik.values.uffici_c_ade}   label="Centro Ag.Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.uffici_c_ade)} />
                                            <ErrorMessage name="uffici_c_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        SemiCentro Agenzia 
                                        <div> 
                                            <InputText name="uffici_s_ag"  onChange={formik.handleChange}  value={formik.values.uffici_s_ag}   label="SemiCentro Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.uffici_s_ag)} />
                                            <ErrorMessage name="uffici_s_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        SemiCentro Ag.Entrate 
                                        <div> 
                                            <InputText name="uffici_s_ade"  onChange={formik.handleChange}  value={formik.values.uffici_s_ade}   label="SemiCentro Ag.Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.uffici_s_ade)} />
                                            <ErrorMessage name="uffici_s_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Periferia Agenzia 
                                        <div> 
                                            <InputText name="uffici_p_ag"  onChange={formik.handleChange}  value={formik.values.uffici_p_ag}   label="Periferia Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.uffici_p_ag)} />
                                            <ErrorMessage name="uffici_p_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Periferia Ag.Entrate 
                                        <div> 
                                            <InputText name="uffici_p_ade"  onChange={formik.handleChange}  value={formik.values.uffici_p_ade}   label="Periferia Ag.Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.uffici_p_ade)} />
                                            <ErrorMessage name="uffici_p_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        <b>Terreni Agricoli</b> Centro Agenzia 
                                        <div> 
                                            <InputText name="terreni_agricoli_c_ag"  onChange={formik.handleChange}  value={formik.values.terreni_agricoli_c_ag}   label="<b>Terreni Agricoli</b> Centro Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.terreni_agricoli_c_ag)} />
                                            <ErrorMessage name="terreni_agricoli_c_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Terreni Agricoli Ag.Entrate 
                                        <div> 
                                            <InputText name="terreni_agricoli_c_ade"  onChange={formik.handleChange}  value={formik.values.terreni_agricoli_c_ade}   label="Terreni Agricoli Ag.Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.terreni_agricoli_c_ade)} />
                                            <ErrorMessage name="terreni_agricoli_c_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        SemiCentro Agenzia 
                                        <div> 
                                            <InputText name="terreni_agricoli_s_ag"  onChange={formik.handleChange}  value={formik.values.terreni_agricoli_s_ag}   label="SemiCentro Agenzia" type="number" placeholder="accedere SemiCentro Agenzia"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.terreni_agricoli_s_ag)} />
                                            <ErrorMessage name="terreni_agricoli_s_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        SemiCentro Ag.Entrate 
                                        <div> 
                                            <InputText name="terreni_agricoli_s_ade"  onChange={formik.handleChange}  value={formik.values.terreni_agricoli_s_ade}   label="SemiCentro Ag.Entrate" type="number" placeholder="accedere SemiCentro Ag.Entrate"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.terreni_agricoli_s_ade)} />
                                            <ErrorMessage name="terreni_agricoli_s_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Periferia agenzia 
                                        <div> 
                                            <InputText name="terreni_agricoli_p_ag"  onChange={formik.handleChange}  value={formik.values.terreni_agricoli_p_ag}   label="Periferia agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.terreni_agricoli_p_ag)} />
                                            <ErrorMessage name="terreni_agricoli_p_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Periferia Ag.Entrate 
                                        <div> 
                                            <InputText name="terreni_agricoli_p_ade"  onChange={formik.handleChange}  value={formik.values.terreni_agricoli_p_ade}   label="Periferia Ag.Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.terreni_agricoli_p_ade)} />
                                            <ErrorMessage name="terreni_agricoli_p_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        <b>Terreni Edificabili</b> Centro Agenzia 
                                        <div> 
                                            <InputText name="terreni_edificabili_c_ag"  onChange={formik.handleChange}  value={formik.values.terreni_edificabili_c_ag}   label="<b>Terreni Edificabili</b> Centro Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.terreni_edificabili_c_ag)} />
                                            <ErrorMessage name="terreni_edificabili_c_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Centro Ag.Entrate 
                                        <div> 
                                            <InputText name="terreni_edificabili_c_ade"  onChange={formik.handleChange}  value={formik.values.terreni_edificabili_c_ade}   label="Centro Ag.Entrate" type="number" placeholder="accedere Centro Ag.Entrate"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.terreni_edificabili_c_ade)} />
                                            <ErrorMessage name="terreni_edificabili_c_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        SemiCentro Agenzia 
                                        <div> 
                                            <InputText name="terreni_edificabili_s_ag"  onChange={formik.handleChange}  value={formik.values.terreni_edificabili_s_ag}   label="SemiCentro Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.terreni_edificabili_s_ag)} />
                                            <ErrorMessage name="terreni_edificabili_s_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        SemiCentro Ag.Entrate 
                                        <div> 
                                            <InputText name="terreni_edificabili_s_ade"  onChange={formik.handleChange}  value={formik.values.terreni_edificabili_s_ade}   label="SemiCentro Ag.Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.terreni_edificabili_s_ade)} />
                                            <ErrorMessage name="terreni_edificabili_s_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Periferia Agenzia 
                                        <div> 
                                            <InputText name="terreni_edificabili_p_ag"  onChange={formik.handleChange}  value={formik.values.terreni_edificabili_p_ag}   label="Periferia Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.terreni_edificabili_p_ag)} />
                                            <ErrorMessage name="terreni_edificabili_p_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Periferia Ag.Entrate 
                                        <div> 
                                            <InputText name="terreni_edificabili_p_ade"  onChange={formik.handleChange}  value={formik.values.terreni_edificabili_p_ade}   label="Periferia Ag.Entrate" type="number" placeholder="accedere Periferia Ag.Entrate"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.terreni_edificabili_p_ade)} />
                                            <ErrorMessage name="terreni_edificabili_p_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        <b>Capannoni</b> Centro Agenzia 
                                        <div> 
                                            <InputText name="capannoni_c_ag"  onChange={formik.handleChange}  value={formik.values.capannoni_c_ag}   label="<b>Capannoni</b> Centro Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.capannoni_c_ag)} />
                                            <ErrorMessage name="capannoni_c_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Centro Ag.Entrate 
                                        <div> 
                                            <InputText name="capannoni_c_ade"  onChange={formik.handleChange}  value={formik.values.capannoni_c_ade}   label="Centro Ag.Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.capannoni_c_ade)} />
                                            <ErrorMessage name="capannoni_c_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        SemiCentro Agenzia 
                                        <div> 
                                            <InputText name="capannoni_s_ag"  onChange={formik.handleChange}  value={formik.values.capannoni_s_ag}   label="SemiCentro Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.capannoni_s_ag)} />
                                            <ErrorMessage name="capannoni_s_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        SemiCentro Ag.Entrate 
                                        <div> 
                                            <InputText name="capannoni_s_ade"  onChange={formik.handleChange}  value={formik.values.capannoni_s_ade}   label="SemiCentro Ag.Entrate" type="number" placeholder="accedere SemiCentro Ag.Entrate"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.capannoni_s_ade)} />
                                            <ErrorMessage name="capannoni_s_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Periferia Agenzia 
                                        <div> 
                                            <InputText name="capannoni_p_ag"  onChange={formik.handleChange}  value={formik.values.capannoni_p_ag}   label="Periferia Agenzia" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.capannoni_p_ag)} />
                                            <ErrorMessage name="capannoni_p_ag" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-2 col-12">
                                        Perifiche Ag.Entrate 
                                        <div> 
                                            <InputText name="capannoni_p_ade"  onChange={formik.handleChange}  value={formik.values.capannoni_p_ade}   label="Perifiche Ag.Entrate" type="number" placeholder="valore"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.capannoni_p_ade)} />
                                            <ErrorMessage name="capannoni_p_ade" component="span" className="p-error" />
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label="Aggiorna" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            );
                            }
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}
LeadsagenzieEditStatistichePage.defaultProps = {
	primaryKey: 'id',
	pageName: 'leadsagenzie',
	apiPath: 'leadsagenzie/edit_statistiche',
	routeName: 'leadsagenzieedit_statistiche',
	submitButtonLabel: "Aggiorna",
	formValidationError: "Il modulo non è valido",
	formValidationMsg: "Si prega di compilare il modulo",
	msgTitle: "Aggiorna registro",
	msgAfterSave: "Record modificato",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default LeadsagenzieEditStatistichePage;
