/**
 * @Category React Hook function
 * Provide single source to manage application static menus items
 * 
**/


export default function useMenus() {
    
    
    return {
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": "Home",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/immobili",
    "label": "Immobili",
    "icon": "pi pi-building",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/leads",
    "label": "Clienti Leads",
    "icon": "pi pi-users",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/leadsagenzie",
    "label": "Agenzie Professionisti",
    "icon": "pi pi-briefcase",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/utenti",
    "label": "Utenti",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/testi",
    "label": "Testi Sito Web",
    "icon": "pi pi-pencil",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/datiomi",
    "label": "Dati Omi",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
  }
],
	tipologia: [    
{value: "Abitazione di tipo civile", label: "Abitazione di tipo civile"},
	{value: "Fabbricato", label: "Fabbricato"},
	{value: "Abitazione di tipo rurale", label: "Abitazione di tipo rurale"}
    ],
	astaDiretta: [    
{value: "DIRETTA", label: "DIRETTA"},
	{value: "INDIRETTA", label: "INDIRETTA"}
    ],
	pec: [    
{value: "N", label: "N"},
	{value: "S", label: "S"}
    ],
	statoLead: [    
{value: "Nuovo", label: "Nuovo"},
	{value: "Richiamato", label: "Richiamato"},
	{value: "Non interessato", label: "Non interessato"}
    ],
        exportFormats: {
            print: {
                label: 'Print',
                icon: 'pi pi-print',
                type: 'print',
                ext: '',
            },
            pdf: {
                label: 'Pdf',
                icon: 'pi pi-file-pdf',
                type: 'pdf',
                ext: 'pdf',
            },
            excel: {
                label: 'Excel',
                icon: 'pi pi-file-excel',
                type: 'excel',
                ext: 'xlsx',
            },
            csv: {
                label: 'Csv',
                icon: 'pi pi-table',
                type: 'csv',
                ext: 'csv',
            },
        },
    }
}