import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Editor } from 'primereact/editor';
import { InputText } from 'primereact/inputtext';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
const TestiEditPage = (props) => {
		const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
		testo_breve_auction: yup.string().nullable().label("Testo Auction Home page"),
		testo_esteso_auction: yup.string().nullable().label("Testo Esteso Auction"),
		testo_breve_dati: yup.string().nullable().label("Testo Dati Informatici Home page"),
		testo_esteso_dati: yup.string().nullable().label("Testo Esteso Dati Informatici"),
		testo_breve_commercia: yup.string().nullable().label("Testo Commercializzazion Home page"),
		testo_esteso_commercia: yup.string().nullable().label("Testo Esteso Commercializzazione"),
		testo_breve_broker: yup.string().nullable().label("Testo Broker Opinion Home page"),
		testo_esteso_broker: yup.string().nullable().label("Testo Esteso Broker Opinion"),
		testo_chi_siamo: yup.string().nullable().label("Testo Chi Siamo"),
		testo_privacy: yup.string().nullable().label("Testo Privacy Policy"),
		testo_cookies: yup.string().nullable().label("Testo Cookies"),
		slogan_titolo: yup.string().nullable().label("Slogan Titolo"),
		slogan_descrizione: yup.string().nullable().label("Slogan Descrizione")
	});
	// form default values
	const formDefaultValues = {
		testo_breve_auction: '', 
		testo_esteso_auction: '', 
		testo_breve_dati: '', 
		testo_esteso_dati: '', 
		testo_breve_commercia: '', 
		testo_esteso_commercia: '', 
		testo_breve_broker: '', 
		testo_esteso_broker: '', 
		testo_chi_siamo: '', 
		testo_privacy: '', 
		testo_cookies: '', 
		slogan_titolo: "NULL", 
		slogan_descrizione: '', 
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/testi`);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="TestiEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container-fluid">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="modificare"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container-fluid">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik
                            initialValues={formData}
                            validationSchema={validationSchema} 
                            onSubmit={(values, actions) => {
                            submitForm(values);
                            }
                            }
                            >
                            { (formik) => {
                            return (
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Testo Auction Home page 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="testo_breve_auction"  onChange={formik.handleChange}  value={formik.values.testo_breve_auction}   label="Testo Auction Home page" type="text" placeholder="Breve Auction"        className={inputClassName(formik?.errors?.testo_breve_auction)} />
                                                <ErrorMessage name="testo_breve_auction" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Testo Esteso Auction 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <div>
                                                    <Editor name="testo_esteso_auction" onTextChange={(e) => formik.setFieldValue('testo_esteso_auction', e.htmlValue)} label="Testo Esteso Auction"  placeholder="Testo Esteso Auction" value={formik.values.testo_esteso_auction} style={{height: '100px'}} className={inputClassName(formik?.errors?.testo_esteso_auction)} />
                                                </div>
                                                <ErrorMessage name="testo_esteso_auction" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Testo Dati Informatici Home page 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="testo_breve_dati"  onChange={formik.handleChange}  value={formik.values.testo_breve_dati}   label="Testo Dati Informatici Home page" type="text" placeholder="Testo Dati Informatici Home page"        className={inputClassName(formik?.errors?.testo_breve_dati)} />
                                                <ErrorMessage name="testo_breve_dati" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Testo Esteso Dati Informatici 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <div>
                                                    <Editor name="testo_esteso_dati" onTextChange={(e) => formik.setFieldValue('testo_esteso_dati', e.htmlValue)} label="Testo Esteso Dati Informatici"  placeholder="Testo Esteso Dati Informatici" value={formik.values.testo_esteso_dati} style={{height: '100px'}} className={inputClassName(formik?.errors?.testo_esteso_dati)} />
                                                </div>
                                                <ErrorMessage name="testo_esteso_dati" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Testo Commercializzazion Home page 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="testo_breve_commercia"  onChange={formik.handleChange}  value={formik.values.testo_breve_commercia}   label="Testo Commercializzazion Home page" type="text" placeholder="Testo Commercializzazion Home page"        className={inputClassName(formik?.errors?.testo_breve_commercia)} />
                                                <ErrorMessage name="testo_breve_commercia" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Testo Esteso Commercializzazione 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <div>
                                                    <Editor name="testo_esteso_commercia" onTextChange={(e) => formik.setFieldValue('testo_esteso_commercia', e.htmlValue)} label="Testo Esteso Commercializzazione"  placeholder="Testo Esteso Commercializzazione" value={formik.values.testo_esteso_commercia} style={{height: '100px'}} className={inputClassName(formik?.errors?.testo_esteso_commercia)} />
                                                </div>
                                                <ErrorMessage name="testo_esteso_commercia" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Testo Broker Opinion Home page 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="testo_breve_broker"  onChange={formik.handleChange}  value={formik.values.testo_breve_broker}   label="Testo Broker Opinion Home page" type="text" placeholder="Testo Broker Opinion Home page"        className={inputClassName(formik?.errors?.testo_breve_broker)} />
                                                <ErrorMessage name="testo_breve_broker" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Testo Esteso Broker Opinion 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <div>
                                                    <Editor name="testo_esteso_broker" onTextChange={(e) => formik.setFieldValue('testo_esteso_broker', e.htmlValue)} label="Testo Esteso Broker Opinion"  placeholder="Testo Esteso Broker Opinion" value={formik.values.testo_esteso_broker} style={{height: '100px'}} className={inputClassName(formik?.errors?.testo_esteso_broker)} />
                                                </div>
                                                <ErrorMessage name="testo_esteso_broker" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Testo Chi Siamo 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <div>
                                                    <Editor name="testo_chi_siamo" onTextChange={(e) => formik.setFieldValue('testo_chi_siamo', e.htmlValue)} label="Testo Chi Siamo"  placeholder="Testo Chi Siamo" value={formik.values.testo_chi_siamo} style={{height: '100px'}} className={inputClassName(formik?.errors?.testo_chi_siamo)} />
                                                </div>
                                                <ErrorMessage name="testo_chi_siamo" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Testo Privacy Policy 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <div>
                                                    <Editor name="testo_privacy" onTextChange={(e) => formik.setFieldValue('testo_privacy', e.htmlValue)} label="Testo Privacy Policy"  placeholder="Testo Privacy Policy" value={formik.values.testo_privacy} style={{height: '100px'}} className={inputClassName(formik?.errors?.testo_privacy)} />
                                                </div>
                                                <ErrorMessage name="testo_privacy" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Testo Cookies 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <div>
                                                    <Editor name="testo_cookies" onTextChange={(e) => formik.setFieldValue('testo_cookies', e.htmlValue)} label="Testo Cookies"  placeholder="Testo Cookies" value={formik.values.testo_cookies} style={{height: '100px'}} className={inputClassName(formik?.errors?.testo_cookies)} />
                                                </div>
                                                <ErrorMessage name="testo_cookies" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Slogan Titolo 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="slogan_titolo"  onChange={formik.handleChange}  value={formik.values.slogan_titolo}   label="Slogan Titolo" type="text" placeholder="accedere Slogan Titolo"        className={inputClassName(formik?.errors?.slogan_titolo)} />
                                                <ErrorMessage name="slogan_titolo" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Slogan Descrizione 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <div>
                                                    <Editor name="slogan_descrizione" onTextChange={(e) => formik.setFieldValue('slogan_descrizione', e.htmlValue)} label="Slogan Descrizione"  placeholder="accedere Slogan Descrizione" value={formik.values.slogan_descrizione} style={{height: '100px'}} className={inputClassName(formik?.errors?.slogan_descrizione)} />
                                                </div>
                                                <ErrorMessage name="slogan_descrizione" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label="Update" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            );
                            }
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}
TestiEditPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'testi',
	apiPath: 'testi/edit',
	routeName: 'testiedit',
	submitButtonLabel: "Update",
	formValidationError: "Il modulo non è valido",
	formValidationMsg: "Si prega di compilare il modulo",
	msgTitle: "Aggiorna registro",
	msgAfterSave: "Record updated successfully",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default TestiEditPage;
