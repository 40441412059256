import { Routes, Route, Navigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

import IndexLayout from 'layouts/IndexLayout';
import MainLayout from 'layouts/MainLayout';
import AuthRoutes from 'components/AuthRoutes';
import IndexPage from 'pages/index/IndexPage';
import DatiomiList from 'pages/datiomi/List';
import DatiomiView from 'pages/datiomi/View';
import DatiomiAdd from 'pages/datiomi/Add';
import DatiomiEdit from 'pages/datiomi/Edit';
import ImmobiliList from 'pages/immobili/List';
import ImmobiliView from 'pages/immobili/View';
import ImmobiliAdd from 'pages/immobili/Add';
import ImmobiliEdit from 'pages/immobili/Edit';
import ImmobiliList30 from 'pages/immobili/List30';
import ImmobiliList7 from 'pages/immobili/List7';
import LeadsList from 'pages/leads/List';
import LeadsView from 'pages/leads/View';
import LeadsAdd from 'pages/leads/Add';
import LeadsEdit from 'pages/leads/Edit';
import LeadsagenzieList from 'pages/leadsagenzie/List';
import LeadsagenzieView from 'pages/leadsagenzie/View';
import LeadsagenzieAdd from 'pages/leadsagenzie/Add';
import LeadsagenzieEdit from 'pages/leadsagenzie/Edit';
import LeadsagenzieEditstatistiche from 'pages/leadsagenzie/EditStatistiche';
import TestiList from 'pages/testi/List';
import TestiEdit from 'pages/testi/Edit';
import UtentiList from 'pages/utenti/List';
import UtentiView from 'pages/utenti/View';
import UtentiAdd from 'pages/utenti/Add';
import UtentiEdit from 'pages/utenti/Edit';
import AccountPages from 'pages/account';
import HomePage from './pages/home/HomePage';
import IndexPages from './pages/index';
import ErrorPages from './pages/errors';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'assets/styles/layout.scss';
const App = () => {
	const auth = useAuth();
	function DefaultPage(){
		if(!auth.isLoggedIn){
			return <IndexPage />
		}
		return <Navigate to="/home" replace />;
	}
	return (
		<Routes>
			<Route exact element={<AuthRoutes />}>
			<Route element={<MainLayout />}>
				<Route path="/home" element={<HomePage />} />
				

				{/* datiomi pages routes */}
				<Route path="/datiomi" element={<DatiomiList />} />
				<Route path="/datiomi/:fieldName/:fieldValue" element={<DatiomiList />} />
				<Route path="/datiomi/index/:fieldName/:fieldValue" element={<DatiomiList />} />
				<Route path="/datiomi/view/:pageid" element={<DatiomiView />} />
				<Route path="/datiomi/add" element={<DatiomiAdd />} />
				<Route path="/datiomi/edit/:pageid" element={<DatiomiEdit />} />

				{/* immobili pages routes */}
				<Route path="/immobili" element={<ImmobiliList />} />
				<Route path="/immobili/:fieldName/:fieldValue" element={<ImmobiliList />} />
				<Route path="/immobili/index/:fieldName/:fieldValue" element={<ImmobiliList />} />
				<Route path="/immobili/view/:pageid" element={<ImmobiliView />} />
				<Route path="/immobili/add" element={<ImmobiliAdd />} />
				<Route path="/immobili/edit/:pageid" element={<ImmobiliEdit />} />
				<Route path="/immobili/list30" element={<ImmobiliList30 />} />
				<Route path="/immobili/list30/:fieldName/:fieldValue" element={<ImmobiliList30 />} />
				<Route path="/immobili/list7" element={<ImmobiliList7 />} />
				<Route path="/immobili/list7/:fieldName/:fieldValue" element={<ImmobiliList7 />} />

				{/* leads pages routes */}
				<Route path="/leads" element={<LeadsList />} />
				<Route path="/leads/:fieldName/:fieldValue" element={<LeadsList />} />
				<Route path="/leads/index/:fieldName/:fieldValue" element={<LeadsList />} />
				<Route path="/leads/view/:pageid" element={<LeadsView />} />
				<Route path="/leads/add" element={<LeadsAdd />} />
				<Route path="/leads/edit/:pageid" element={<LeadsEdit />} />

				{/* leadsagenzie pages routes */}
				<Route path="/leadsagenzie" element={<LeadsagenzieList />} />
				<Route path="/leadsagenzie/:fieldName/:fieldValue" element={<LeadsagenzieList />} />
				<Route path="/leadsagenzie/index/:fieldName/:fieldValue" element={<LeadsagenzieList />} />
				<Route path="/leadsagenzie/view/:pageid" element={<LeadsagenzieView />} />
				<Route path="/leadsagenzie/add" element={<LeadsagenzieAdd />} />
				<Route path="/leadsagenzie/edit/:pageid" element={<LeadsagenzieEdit />} />
				<Route path="/leadsagenzie/edit_statistiche/:pageid" element={<LeadsagenzieEditstatistiche />} />

				{/* testi pages routes */}
				<Route path="/testi" element={<TestiList />} />
				<Route path="/testi/:fieldName/:fieldValue" element={<TestiList />} />
				<Route path="/testi/index/:fieldName/:fieldValue" element={<TestiList />} />
				<Route path="/testi/edit/:pageid" element={<TestiEdit />} />

				{/* utenti pages routes */}
				<Route path="/utenti" element={<UtentiList />} />
				<Route path="/utenti/:fieldName/:fieldValue" element={<UtentiList />} />
				<Route path="/utenti/index/:fieldName/:fieldValue" element={<UtentiList />} />
				<Route path="/utenti/view/:pageid" element={<UtentiView />} />
				<Route path="/utenti/add" element={<UtentiAdd />} />
				<Route path="/utenti/edit/:pageid" element={<UtentiEdit />} />
				<Route path="/account/*" element={<AccountPages />} />
			</Route>
			</Route>
			<Route exact element={<IndexLayout />}>
				<Route path="/" element={<DefaultPage />} />
				<Route path="/*" element={<IndexPages />} />
				<Route path="/error/*" element={<ErrorPages />} />
			</Route>
		</Routes>
	);
}
export default App;
